import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { handleErrorResponse, isUuid } from 'utils/general';
// eslint-disable-next-line import/no-cycle
import { sendApiRequest } from 'api/general';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicTableRow } from 'api/sections';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import MKBox from 'components/MaterialKit/MKBox';
import Grid from '@mui/material/Grid';
import Button from 'components/Button';
import { font } from './font';

const FINGERPRINT_MASTER_COLLECTION_DEFINITION_ID = 'ae2a2f58-99d8-4744-bab5-1249c7d7e293';
const METADATA_COLLECTION_DEFINITION_ID = '0fb16a96-852a-46a6-a039-14841371124f';
const AUDIO_MATCH_REPORT_COLLECTION_DEFINITION_ID = '9775e8d4-0635-4eb3-8c1b-beca0a04d27d';

const AMSReportExportSection = ({
  collection_definition_id,
  report_id,
  target_url,
  target_id_parameter,
  params,
  use_xml,
  use_pdf,
  use_csv,
  button_props,
  ...props
}) => {
  const { setAuth } = useAuth();
  const [collectionDefinition, setCollectionDefinition] = useState(null);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        }).catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  const fetchDataFromApi = useCallback(() => {
    const parameters = { ...params };
    if (parameters[target_id_parameter] === undefined) {
      parameters[target_id_parameter] = report_id;
    }
    return sendApiRequest(target_url, 'get', parameters, 'bearer')
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [params, report_id, setAuth, target_id_parameter, target_url]);

  const fetchDynamicTableRow = useCallback(() => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const rowParams = { $expand: 'fingerprint_master,metadata' };
    return getDynamicTableRow(collection_definition_id, report_id, rowParams)
      .then(({ data }) => {
        return getDynamicData(data, collectionDefinition.attributes);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition, collection_definition_id, report_id, setAuth]);

  const prepareRows = (data) => {
    const { items, report_data } = data;
    const rows = items.map((item) => {
      return {
        name: report_data.name,
        target_file_info: report_data.target_file_info,
        target_date_time: report_data.target_datetime,
        target_term_ref: report_data.target_term_ref,
        operator: report_data.operator,
        target_folder: report_data.target_folder,
        fingerprint_master: report_data.fingerprint_master,
        matching_threshold: report_data.matching_threshold,
        metadata: report_data.metadata,
        start_time: report_data.start_time,
        end_time: report_data.end_time,
        status: report_data.status,
        matched_isrc: item.metadata_item.isrc,
        hh_mm_ss: item.start_time,
        in_seconds: item.duration,
        recording_title: item.metadata_item.recording_title,
        artiste: item.metadata_item.artiste,
        release_label: item.metadata_item.release_label,
        claimant_name: item.metadata_item.claimant_name,
        copyright_owner: item.metadata_item.copyright_owner,
        type_of_recording: item.metadata_item.type_of_recording,
        authorization_start_date: item.metadata_item.authorization_start_date,
        claimant_party_id: item.metadata_item.claimant_party_id,
        first_release_date_global: item.metadata_item.first_release_date_global,
        first_release_date_hong_kong: item.metadata_item.first_release_date_hong_kong,
        membership_start_date: item.metadata_item.membership_start_date,
        membership_end_date: item.metadata_item.membership_end_date,
        user_defined_1: item?.user_defined_1,
        user_defined_2: item?.user_defined_2,
        user_defined_3: item?.user_defined_3,
      };
    });
    return rows;
  };

  const handlePDF = useCallback((data) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('l', 'pt', [2970, 210], true);
    doc.addFileToVFS('noto.ttf', font);
    doc.addFont('noto.ttf', 'Noto', 'normal');
    doc.setFont('Noto', 'normal');
    const columns = [
      'Report Name', 'Target File Info', 'Target Date/Time', 'Target Term Ref',
      'Operator', 'Target Folder', 'Fingerprint Master', 'Matching Threshold',
      'Metadata', 'Start Time', 'End Time', 'Status', 'Matched ISRC', 'HH:mm:ss', 'In Seconds', 'Recording Title',
      'Artiste', 'Release Label Claimant Name', 'Copyright Owner',
      'Type of Recording', 'Authorization Start Date', 'Claimant Party ID',
      'First Release Date (Global)', 'First Release Date (Hong Kong)',
      'Membership Start Date', 'Membership End Date',
      'User Defined 1', 'User Defined 2', 'User Defined 3',
    ];
    const rows = prepareRows(data).map((item) => Object.values(item));

    doc.autoTable({
      head: [columns],
      body: rows,
      styles: {
        font: 'Noto',
      },
      margin: { top: 0, left: 0, right: 0, bottom: 0 },
    });

    const date = moment().format('YYYY-MM-DD_hh:mm:ss');
    doc.save(`export_${date}.pdf`);
  }, []);

  const handleCSV = useCallback((data) => {
    const columns = [
      'Report Name', 'Target File Info', 'Target Date/Time', 'Target Term Ref',
      'Operator', 'Target Folder', 'Fingerprint Master', 'Matching Threshold',
      'Metadata', 'Start Time', 'End Time', 'Status', 'Matched ISRC', 'HH:mm:ss', 'In Seconds', 'Recording Title',
      'Artiste', 'Release Label Claimant Name', 'Copyright Owner',
      'Type of Recording', 'Authorization Start Date', 'Claimant Party ID',
      'First Release Date (Global)', 'First Release Date (Hong Kong)',
      'Membership Start Date', 'Membership End Date',
      'User Defined 1', 'User Defined 2', 'User Defined 3',
    ];
    const rows = prepareRows(data);
    const csvContent = [
      columns.join(','),
      ...rows.map((item) => {
        return Object.values(item).join(',');
      }),
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      const date = moment().format('YYYY-MM-DD_hh:mm:ss');
      link.setAttribute('href', url);
      link.setAttribute('download', `${data.report_data.name}_${date}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  const handleXML = useCallback((data) => {
    const xmlDoc = document.implementation.createDocument('', '', null);
    const root = xmlDoc.createElement('reports');
    xmlDoc.appendChild(root);
    const rows = prepareRows(data);
    rows.forEach((item) => {
      const itemElement = xmlDoc.createElement('report');
      Object.entries(item).forEach(([key, value]) => {
        const element = xmlDoc.createElement(key);
        element.textContent = value;
        itemElement.appendChild(element);
      });
      root.appendChild(itemElement);
    });
    const serializer = new XMLSerializer();
    const xmlStr = serializer.serializeToString(xmlDoc);
    const blob = new Blob([xmlStr], { type: 'application/xml' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      const date = moment().format('YYYY-MM-DD_hh:mm:ss');
      link.setAttribute('href', url);
      link.setAttribute('download', `${data.report_data.name}_${date}.xml`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  const getDynamicObjNameField = (collectionDefinitionId, dynamicObjId) => {
    return getCollectionDefinition(collectionDefinitionId, { $expand: 'attributes' }).then(({ data: collectionDefinitionData }) => {
      return getDynamicTableRow(collectionDefinitionId, dynamicObjId).then(({ data: dynamicObj }) => {
        const dynamicData = getDynamicData(dynamicObj, collectionDefinitionData.attributes);
        return { id: collectionDefinitionId, name: dynamicData.name };
      });
    });
  };

  const exportData = useCallback((extension) => {
    fetchDataFromApi()
      .then((items) => {
        fetchDynamicTableRow()
          .then((dynamicTableRow) => {
            const data = dynamicTableRow;
            const promises = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(data)) {
              if (key.includes('_time') || key.includes('_datetime')) {
                data[key] = moment(+value).format('YYYY-MM-DD hh:mm:ss');
              }
            }
            if (collection_definition_id === AUDIO_MATCH_REPORT_COLLECTION_DEFINITION_ID) {
              const fingerprintMasterId = get(dynamicTableRow, 'fingerprint_master');
              const metadataId = get(dynamicTableRow, 'metadata');
              promises.push(getDynamicObjNameField(FINGERPRINT_MASTER_COLLECTION_DEFINITION_ID, fingerprintMasterId));
              promises.push(getDynamicObjNameField(METADATA_COLLECTION_DEFINITION_ID, metadataId));
            }

            Promise.all(promises).then((responses) => {
              responses.forEach((res) => {
                const { id, name } = res;
                if (id === FINGERPRINT_MASTER_COLLECTION_DEFINITION_ID) {
                  data.fingerprint_master = name;
                } else if (id === METADATA_COLLECTION_DEFINITION_ID) {
                  data.metadata = name;
                }
              });
              const reportData = {
                items,
                report_data: data,
              };
              switch (extension) {
                case 'pdf':
                  handlePDF(reportData);
                  break;
                case 'csv':
                  handleCSV(reportData);
                  break;
                case 'xml':
                  handleXML(reportData);
                  break;
                default:
                  break;
              }
            });
          });
      });
  }, [collection_definition_id, fetchDataFromApi, fetchDynamicTableRow, handleCSV, handlePDF, handleXML]);

  return (
    <MKBox
      {...props}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {use_pdf && (
          <Grid item xs={4}>
            <Button
              variant="text"
              size="medium"
              fontSize="16px"
              fullWidth
              onClick={() => { exportData('pdf'); }}
              iconOnly={false}
              disabled={false}
              circular={false}
              {...button_props}
            >
              Export (PDF)
            </Button>
          </Grid>
        )}
        {use_csv && (
          <Grid item xs={4}>
            <Button
              variant="text"
              size="medium"
              fontSize="16px"
              fullWidth
              onClick={() => { exportData('csv'); }}
              iconOnly={false}
              disabled={false}
              circular={false}
              {...button_props}
            >
              Export (CSV)
            </Button>
          </Grid>
        )}
        {use_xml && (
          <Grid item xs={4}>
            <Button
              variant="text"
              size="medium"
              fontSize="16px"
              fullWidth
              onClick={() => { exportData('xml'); }}
              iconOnly={false}
              disabled={false}
              circular={false}
              {...button_props}
            >
              Export (XML)
            </Button>
          </Grid>
        )}
      </Grid>
    </MKBox>
  );
};

AMSReportExportSection.propTypes = {
  collection_definition_id: PropTypes.string,
  report_id: PropTypes.string,
  target_url: PropTypes.string,
  target_id_parameter: PropTypes.string,
  params: PropTypes.object,
  button_props: PropTypes.object,
  use_xml: PropTypes.bool,
  use_pdf: PropTypes.bool,
  use_csv: PropTypes.bool,
};

export default AMSReportExportSection;
